import LoadingScreen from "./screens/LoadingScreen";
import LoginPage from "./screens/Login";
import MainScreen from "./screens/main";
import { useSetState } from "react-use";
import { useEffect } from "react";
import axios from "axios";

function App() {
  const [auth, setAuth] = useSetState({ isLogin: false, loading: true });

  useEffect(() => {
    console.log(setAuth)
    const token = localStorage.getItem("user_token");
  
    if (token) {
      // axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      axios
        .post(`/validate/current/${token}`)
       // .get("/auth/current")
        .then(() => {
          setAuth({ isLogin: true, loading: false });
        })
        .catch(() => {
          localStorage.removeItem("user_token");
          setAuth({ loading: false, isLogin: false });
        });
    } else {
      setAuth({ loading: false });
    }
  }, [setAuth]);

  useEffect(() => {

    let token = localStorage.getItem("user_token")
 
    if(token){
      setAuth({ isLogin: true });
    }

  }, []);


  return (
    <>
      {auth.loading && <LoadingScreen />}
      {!auth.isLogin && !auth.loading && <LoginPage setAuth={setAuth} />}
      {auth.isLogin && <MainScreen setAuth={setAuth} />}
      {/* <div style={{ display: auth.isLogin ? "block" : "none" }}>
        
      </div> */}
    </>
  );
}

export default App;
