import { useForm } from "react-hook-form";
import SelectHookForm from "./hook-form/SelectHookForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "react-query";
import axios from "axios";
import { fileServer } from "../baseURL";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  operator_id: yup.string(),
  school: yup.string(),
  grade: yup.string(),
  subject: yup.string(),
  classname: yup.string(),
  period: yup.number().default(1),
});

export const defaultValues = {
  operator_id: "",
  school: "",
  grade: "",
  subject: "",
  classname: "",
  period: 1,
};

const SidebarArea = ({
  setSearchObject,
  setTableState,
  onSyncButtonClick,
  loading,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    criteriaMode: "all",
    defaultValues,
  });

  const formValues = watch();

  const { data: operatorSuggestions = [] } = useQuery("operator", async () => {
    const { data } = await axios.get("/operators");
    return data.map((val) => ({
      value: val.operatorId,
      label: val.operatorName,
    }));
  });

  const { data: schoolSuggestions = [] } = useQuery(
    ["school", formValues.operator_id],
    async () => {
      const { data } = await axios.get(
        `/schools/operators/${formValues.operator_id}`
      );

      return data.map((val) => ({
        value: val.abbreviation,
        label: val.englishName,
        code: val.code
      }));
    }
  );



  const { data: gradeSuggestions = [] } = useQuery(
    ["grade", formValues.school, formValues.operator],
    async () => {
      let code = schoolSuggestions.find(c => c.value == formValues.school) && schoolSuggestions.find(c => c.value == formValues.school).code;
      console.log(code)
      const { data } = await axios.get(
        `/grades/schoolcode/${code}`
      );
      return data.map((val) => ({
        value: val.grade,
        label: val.grade,
        Id: val.id,
      }));
    }
  );

  const { data: classesSuggestions = [] } = useQuery(
    [
      "classname",
      formValues.school,
      formValues.grade,
      formValues.operator_id,
    ],
    async () => {
      let GId = gradeSuggestions.find(c => c.value == formValues.grade) && gradeSuggestions.find(c => c.value == formValues.grade).Id;
      const { data } = await axios.get(
        `/classes/${GId}`
      );
      return data.map((val) => ({
        value: val.secondaryName,
        label: val.secondaryName,
      }));
    }
  );

  const { data: subjectSuggestions = [] } = useQuery(
    [
      "subject",
      formValues.classname,
      formValues.school,
      formValues.grade,
      formValues.operator_id,
    ],
    async () => {
      const { data } = await axios.get(
        `/subject?class_name=${formValues.classname}&school=${formValues.school}&grade=${formValues.grade}&operator_id=${formValues.operator_id}`
      );
      return data.data.map((val) => ({
        value: val.subject_name,
        label: val.subject_name,
      }));
    }
  );

  const onExportClick = () => {
    axios
   // .get(`/export?classname=${formValues.classname}&school=${formValues.school}&subject=${formValues.subject}&period=${formValues.period}&grade=${formValues.grade}&operator_id=${formValues.operator_id}`)
    .get(`/GetExportFileData/1?operator_id=${formValues.operator_id}&school=${formValues.school}&grade=${formValues.grade}&classname=${formValues.classname}`)
    .then((res) => {
        window.open(fileServer + res.data.data, "_blank");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const onSubmit = (values) => {
    setSearchObject(values);
    setTableState({ pageIndex: 0 });
  };

  const onClickReset = () => {
    setSearchObject(defaultValues);
    reset(defaultValues);
    setTableState({ pageIndex: 0 });
  };

  return (
    <div className="col-lg-3 col-md-6 col-12">
      <div className="sidebar sticky-bar p-4 rounded shadow">
        <div className="widget">
          <ul className="list-unstyled sidebar-nav mb-0">
            <h5 className="text-dark title">Choose from the list</h5>
            <li className="navbar-item account-menu px-0 mt-2">
              <SelectHookForm
                name="operator_id"
                placeholder="Operator"
                control={control}
                selectClasses="btn-info"
                dependents={[
                  "school",
                  "grade",
                  "subject",
                  "classname"
                ]}
                setValue={setValue}
                isSubmitted={isSubmitted}
                errors={errors}
                options={operatorSuggestions}
              />
            </li>

            <li className="navbar-item account-menu px-0 mt-2">
              <SelectHookForm
                name="school"
                placeholder="School"
                control={control}
                selectClasses="btn-success"
                dependents={["grade", "subject", "classname"]}
                setValue={setValue}
                errors={errors}
                isSubmitted={isSubmitted}
                options={schoolSuggestions}
              />
            </li>

            <li className="navbar-item account-menu px-0 mt-2">
              <SelectHookForm
                name="grade"
                placeholder="Grade"
                control={control}
                selectClasses="btn-muted"
                dependents={["subject", "classname"]}
                setValue={setValue}
                errors={errors}
                isSubmitted={isSubmitted}
                options={gradeSuggestions}
              />
            </li>

            <li className="navbar-item account-menu px-0 mt-2">
              <SelectHookForm
                name="classname"
                placeholder="Classes"
                control={control}
                selectClasses="text-warning"
                dependents={["subject"]}
                setValue={setValue}
                errors={errors}
                isSubmitted={isSubmitted}
                options={classesSuggestions}
              />
            </li>

            {/* <li className="navbar-item account-menu px-0 mt-2">
              <SelectHookForm
                name="subject"
                placeholder="Subject"
                control={control}
                selectClasses="text-warning"
                dependents={[]}
                setValue={setValue}
                errors={errors}
                isSubmitted={isSubmitted}
                options={subjectSuggestions}
              />
            </li> */}

            {/* <li className="navbar-item account-menu px-0 mt-2">
              <SelectHookForm
                name="period"
                placeholder="Period"
                control={control}
                selectClasses="text-warning"
                dependents={[]}
                setValue={setValue}
                errors={errors}
                isSubmitted={isSubmitted}
                // defaultValue={1}
                options={[
                  {
                    value: 1,
                    label: "Daily",
                  },
                  {
                    value: 7,
                    label: "Weekly",
                  },
                  {
                    value: 30,
                    label: "Monthly",
                  },
                ]}
              />
            </li> */}
          </ul>
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
            className="btn-sm form-control btn-success mb-1 text-center pointer"
          >
            Filter
          </button>
          <button
            disabled={loading}
            onClick={onClickReset}
            className="btn-sm form-control btn-muted text-center  mb-1"
          >
            Reset
          </button>
          <button
            disabled={loading}
            onClick={onExportClick}
            className="btn-sm form-control  btn-info text-center mb-1"
          >
            Export
          </button>
         
        </div>
      </div>
    </div>
  );
};

export default SidebarArea;
