import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  username: yup.string().required("Enter username").email("Enter valid email"),
  password: yup.string().required("Enter password"),
});

const LoginPage = ({ setAuth }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    axios
     // .post("/auth/login", data)   
      .post("/validate/login", data)   
      .then((res) => {
        console.log(res)
         if (res.data.success) {
           console.log(res.data.data.token)
           localStorage.setItem("user_token", res.data.data.token);
           setAuth({ isLogin: true });
           toast.success(res.data.message);
           //axios.defaults.headers.common.Authorization = `Bearer ${res.data.data.token}`;
         }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  return (
    <MainWrapper>
      <LoginBox>
        <img alt="logo" height="60" src="images/logo.png" />
        <Input
          placeholder="Email"
          {...register("username")}
          error={!!errors.username}
        />
        {errors.username && (
          <p className="error-msg">{errors?.username?.message}</p>
        )}

        <Input
          placeholder="Password"
          {...register("password")}
          type="password"
          error={!!errors.password}
        />
        {errors.password && (
          <p className="error-msg">{errors?.password?.message}</p>
        )}
        <button onClick={handleSubmit(onSubmit)}>Login</button>
      </LoginBox>
    </MainWrapper>
  );
};

export default LoginPage;

const MainWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  background-color: rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 40vw;
  }

  img {
    margin-bottom: 20px;
  }

  button {
    background-color: #6366f2;
    width: 100%;
    text-align: center;
    padding: 10px 10px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }

  .error-msg {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    color: red;
    text-align: left;
    width: 100%;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: ${(props) => (props.error ? "0px" : "16px")};
  padding: 0px 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid grey;
`;

