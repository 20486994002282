/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React from "react";
import { usePagination, useTable } from "react-table";
import styled, { css } from "styled-components";
import { Roller } from "react-css-spinners";

const Table = ({
  totalPageSize,
  data,
  tableState,
  gotoPage,
  nextPage,
  previousPage,
  loading = false,
}) => {
  const { pageIndex } = tableState;

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPageSize,
      autoResetPage: false,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            ...tableState,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, tableState]
        );
      },
    },
    usePagination
  );
  console.log("data is table")
  console.log(data)

  return (
    <div className="col-lg-9 col-12">
      <div
        className="table-responsive bg-white shadow-md rounded-md"
        style={{
          background: 'url("images/home-bg.png") right top',
          backgroundAttachment: "fixed",
        }}
      >
        {loading ? (
          <NoDataFoundWrapper>
            <Roller color="black" />
          </NoDataFoundWrapper>
        ) : data.length === 0 ? (
          <NoDataFoundWrapper>
            <p>No Data Found</p>
          </NoDataFoundWrapper>
        ) : (
          <table {...getTableProps()} className="table mb-0 table-center">
            <thead>
              <tr className="bg-info">
                {headers.map((header) => (
                  <th
                    {...header.getHeaderProps()}
                    key={header.id}
                    scope="col"
                    className="border-bottom"
                  >
                    {header.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={i} {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="col-12 mt-4 pt-2">
        <ul className="pagination justify-content-center mb-0">
          <PageItem
            onClick={() =>
              canPreviousPage && !loading ? previousPage() : null
            }
            disabled={!canPreviousPage || loading}
            className="page-item"
          >
            <div className="page-link">Prev</div>
          </PageItem>

          {getPagingRange(pageIndex, {
            min: 0,
            total: totalPageSize,
            length: 5,
          }).map((num, index) => {
            return (
              <PageItem
                key={index}
                onClick={() => (!loading ? gotoPage(num) : null)}
                className={`page-item ${num === pageIndex && "active"} `}
              >
                <div className="page-link">{num + 1}</div>
              </PageItem>
            );
          })}
          <PageItem
            onClick={() => (canNextPage && !loading ? nextPage() : null)}
            disabled={!canNextPage || loading}
            className="page-item"
          >
            <div className="page-link">Next</div>
          </PageItem>
        </ul>
      </div>
    </div>
  );
};

export default Table;

function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
  if (length > total) length = total;

  let start = current - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + total - length);

  return Array.from({ length: length }, (el, i) => start + i);
}

const PageItem = styled.li`
  .page-link {
    cursor: pointer;

    ${(props) =>
      props.disabled
        ? css`
            background-color: grey;
            cursor: not-allowed;
            pointer-events: all !important;
            color: white !important;

            &:hover {
              background-color: grey !important;
            }
          `
        : ""};
  }
`;

const NoDataFoundWrapper = styled.div`
  height: 673px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: grey;
`;

const columns = [
  {
    Header: "Date",
    accessor: "start",
    Cell: (row) => {
      return (
        <div className="event-schedule">
          <div className="d-flex">
            <ul className="date text-center text-primary me-3 mb-0 list-unstyled">
              <li className="day">{moment(row.value).format("D")}</li>
            </ul>
            <div className="flex-1 content mt-2">
              <a href="#" className="text-dark title">
                {moment(row.value).format("dddd") + " "}
              </a>
              <span className="text-warning">
                {moment(row.value).format("MMMM YYYY")}
              </span>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Time",
    accessor: "end",
    Cell: ({ row }) => {
      return (
        <div className="event-schedule rounded border mb-0">
          <div className="card-body1">
            <div className="text-muted">
              <span className="text-warning h6">From</span>{" "}
              {moment(row.original.start).format("LT")}{" "}
              <i className="uil uil-clock text-info" />
              <span className="text-warning h6">To</span>{" "}
              {moment(row.original.end).format("LT")}{" "}
              <i className="uil uil-clock text-info" />
              
              <span className="text-muted title Kufi14">
                {row.original?.subject}
              </span>
              &nbsp;
              <i className="uil uil-book-open text-success" />
              &nbsp;&nbsp;&nbsp;{" "}
              {row.original?.extended_school}
              &nbsp;&nbsp;&nbsp;{" "}
              {row.original?.extended_class}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Active",
    accessor: "web_link",
    Cell: ({row}) => {
      if (row.original?.showlink)
       //if (row.value)
        return (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(row.value, "_blank");
            }}
            className="text-muted h6"
          >
            Join/Open
          </p>
        );
      // else return null;
      else return null;
      },
  },
];
