// Dev
// export const baseURL = "http://207.180.230.73/meetings/v1";
// export const fileServer = "http://207.180.230.73/meetings/";

// Prod
//  export const baseURL = "http://20.74.161.160/Monitoring/v1";
//  export const fileServer = "http://20.74.161.160/Monitoring/";

// Dev
//export const baseURL = "http://monitoring.ctsdemo.ae/Services/v1";
//export const fileServer = "http://monitoring.ctsdemo.ae/Services/";


export const baseURL = "https://monitoring.ctsdemo.ae/api/Monitoring";
export const fileServer = "https://monitoring.ctsdemo.ae/";

